import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  beURL?: string
  description?: string
  languageCode: string
  priceLabel?: string
  priceValue?: string
  quotationPageURL?: string
  sort?: number
  title?: string
}

export const Package = memo(function Package({
  beURL,
  description,
  languageCode,
  priceLabel,
  priceValue,
  quotationPageURL,
  title,
}: Props) {
  return (
    <Container row wrap>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}

          {priceValue ? (
            <Media lessThan="ipadVertical">
              <RightSide>
                <Value>{priceValue}</Value>
                <Label>{priceLabel}</Label>
              </RightSide>
            </Media>
          ) : null}

          {beURL || quotationPageURL ? (
            <Buttons row>
              {beURL ? (
                <Button
                  label={useVocabularyData('book', languageCode)}
                  target="_blank"
                  URL={beURL}
                />
              ) : null}
              {quotationPageURL ? (
                <Button
                  label={useVocabularyData('ask-for', languageCode)}
                  target="_blank"
                  URL={quotationPageURL}
                  variant="primary"
                />
              ) : null}
            </Buttons>
          ) : null}
        </LeftSide>

        {priceValue ? (
          <Media greaterThanOrEqual="ipadVertical">
            <RightSide>
              <Value>{priceValue}</Value>
              <Label>{priceLabel}</Label>
            </RightSide>
          </Media>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 1.25rem;
  padding: 2.625rem 3.125rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 1.5rem;
    padding: 3.5625rem 2.3125rem 2.9375rem;
    text-align: center;
  }
`

const LeftSide = styled.div`
  flex: 1;
  padding-right: 3.375rem;

  @media (max-width: 767px) {
    padding-right: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.75rem;
`

const Buttons = styled(FlexBox)`
  margin-top: 3.125rem;

  a {
    min-width: 10rem;
    &:nth-of-type(2) {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 767px) {
    display: block;
    margin-top: 2.25rem;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 0.625rem 0 0;
      }
    }
  }
`

const RightSide = styled.div`
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 2.125rem 1.875rem;
  text-align: right;

  @media (max-width: 767px) {
    display: inline-block;
    border: 0;
    border-top: 0.125rem solid
      ${({ theme }) => theme.colors.variants.neutralLight2};
    margin: 1.75rem auto 0;
    padding: 1.25rem 1.5625rem 0;
    text-align: center;
  }
`

const Value = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: uppercase;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.375rem;
`
