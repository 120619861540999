import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Validity = memo(function Validity({ description, title }: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 5.5rem;

  @media (max-width: 1023px) {
    margin-top: 5rem;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  &:after {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    flex: 1;
    margin-left: 1.125rem;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-size: 1rem;
  line-height: 2rem;
`
