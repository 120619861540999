import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  title?: string
}

export const Paragraph = memo(function Paragraph({ cta, title }: Props) {
  if (!cta && !title) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {cta ? (
        <FadeInUp>
          <CTA variant="outline" {...cta} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 7.5rem;
  text-align: center;
`

const Title = styled.div`
  max-width: 32.5rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 3.125rem;
  margin: 0 auto 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)``
