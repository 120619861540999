import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  list: string[]
}

export const Legend = memo(function Legend({ list }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {list.map((item: any, index) => (
        <Item key={index}>
          <FadeInUp>{item.description}</FadeInUp>
        </Item>
      ))}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 5rem;

  @media (max-width: 1023px) {
    margin-top: 2.1875rem;
  }
`

const Item = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-size: 0.875rem;
  line-height: 1.3125rem;

  > div {
    margin-top: 1.625rem;
  }
`
